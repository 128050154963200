export const USER_API_PATH = {
  LOGIN_PATH: "Auth/login",
  REGISTRATION_PATH: "Auth/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
  LIST_PATH: "/User/listUsers?roleId=",
};
export const BANNER_API_PATH = {
  LIST_PATH: "Banner/list",
  CREATE_UPDATE_PATH: "Banner/update",
  BANNER_IMAGE: "Banner/bannerUpload",
  BANNER_STORES_CONFIG: "Banner/updateStoreBanners",
  DELETE_BANNER: "Banner/delete?id=",
};

export const SECTION_API_PATH = {
  LIST_PATH: "Section/list",
  CREATE_UPDATE_PATH: "Section/update",
  PRODUCT_IMAGE: "Section/imageUpload",
};

export const SUB_CATGORY_API_PATH = {
  LIST_PATH: "Category/list",
  CREATE_UPDATE_PATH: "Category/update",
  PRODUCT_IMAGE: "Category/imageUpload",
};

export const TAG_API_PATH = {
  LIST_PATH: "Tag/list",
  CREATE_UPDATE_PATH: "Tag/update",
};

export const BRAND_API_PATH = {
  LIST_PATH: "Brand/list",
  CREATE_UPDATE_PATH: "Brand/update",
};

export const PRODUCT_API_PATH = {
  LIST_PATH: "Product/adminItemList",
  PRODUCT_CREATE: "Product/update",
  PRODUCT_PRICE: "ProductPrice/update",
  PRODUCT_STATUS: "Product/updateProductStatus",
  PRODUCT_IMAGE: "ProductImage/imageUpload",
  PRODUCT_PREVIEW_IMAGE: "Product/imageUpload",
  PRODUCT_PRICE_LIST: "ProductPrice/list",
  STORE_PRODUCT_PRICE_LIST: "ProductPrice/stroePrices",
  UPDATE_STORE_PRICES: "ProductPrice/updateStorePrices",
  UPDATE_ALL_STORE_SINGLE_PRICE: "ProductPrice/updateSinglePriceStores",
  STORE_PRODUCT_LIST_PATH: "Product/storeItemList",
  PRODUCT_DETAIL_PATH: "Product/productInfo",
};

export const ORDER_API_PATH = {
  // LIST_PATH: "Order/adminList",
  // CREATE_UPDATE_PATH: "Order/update",

  ORDER_LIST: "Order/List",
  ORDER_DETAILS: "Order/orderInfo?orderId=",
};

export const TAX_SLAB_API_PATH = {
  LIST_PATH: "TaxSlab/list",
  CREATE_UPDATE_PATH: "TaxSlab/update",
};

export const HSN_CODES_API_PATH = {
  LIST_PATH: "HsnCode/list",
  CREATE_UPDATE_PATH: "HsnCode/update",
};

export const COLOR_CODES_API_PATH = {
  LIST_PATH: "MaterialColor/list",
  CREATE_UPDATE_PATH: "MaterialColor/update",
};

export const MATERIAL_TYPE_API_PATH = {
  LIST_PATH: "MaterialType/list",
  CREATE_UPDATE_PATH: "MaterialType/update",
};

export const UOM_API_PATH = {
  LIST_PATH: "Uom/list",
  CREATE_UPDATE_PATH: "Uom/update",
};

export const BRANCH_API_PATH = {
  LIST_PATH: "Store/list",
  CREATE_UPDATE_PATH: "Store/update",
  MAIN_BRANCH_PATH: "Store/getMainBranch",
};

export const COUPONS_API_PATH = {
  COUPON_LIST_PATH: "PromoCode/list?type=",
  CREATE_COUPON: "PromoCode/update",
  DELETE_COUPON: "PromoCode/delete?id=",
  IMAGE_UPLOAD: "PromoCode/imageUpload",
};
