import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={`${logoClass ? logoClass : ""} `}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img
          alt=""
          src={process.env.PUBLIC_URL + imageUrl}
          style={{
            height: 80,
            // width: 320,
            // borderRadius: 50,
            // backgroundColor: "#02025a",
          }}
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
