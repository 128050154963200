import { createSlice } from "@reduxjs/toolkit";
export const formUpdateSlice = createSlice({
  name: "formUpdate",
  initialState: {
    displayForm: false,
    displayRemove: false,
    type: "",
    deleteApiPath: "",
    imageUpload: false,
    formData: {},
  },
  reducers: {
    updateFormInfo: (state, data) => {
      console.log("payload", data.payload.formData);
      state.formData = data.payload.formData;
      state.displayForm = data.payload.displayForm;
      state.imageUpload = data.payload.imageUpload;
    },

    displayForm: (state, data) => {
      console.log("payload", data.payload.formData);
      state.displayForm = data.payload.displayForm;
    },
    displayRemoveAlert: (state, data) => {
      console.log("payload", data.payload.formData);
      state.formData = data.payload.formData;
      state.displayRemove = data.payload.displayRemove;
      state.type = data.payload.type;
      state.deleteApiPath = data.payload.deleteApiPath;
    },
  },
});

export const { updateFormInfo, displayForm, displayRemoveAlert } =
  formUpdateSlice.actions;

export const formUpdateData = (state) =>
  state.rootReducer.formUpdateReducer.formData;

export const formUpdateStatus = (state) =>
  state.rootReducer.formUpdateReducer.displayForm;

export const imageUploadStatus = (state) =>
  state.rootReducer.formUpdateReducer.imageUpload;

export const deleteStatus = (state) =>
  state.rootReducer.formUpdateReducer.displayRemove;

export const itemType = (state) => state.rootReducer.formUpdateReducer.type;

export const deletePath = (state) =>
  state.rootReducer.formUpdateReducer.deleteApiPath;

export default formUpdateSlice.reducer;
