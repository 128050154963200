import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="contact-area pt-70 pb-100">
        <div className="container">
          <div>
            <h3>Privacy Policy</h3>

            <p>
              Thank you for using LAMBIGO. This Privacy Policy describes how
              information about you is collected, used and shared by Lambigo and
              our subsidiaries and other affiliates when you access or use the
              website or Application linked to this Privacy Policy, or blogs
              provided by us.
            </p>
            <br />

            <h1>Consent and Terms</h1>
            <p>
              By using or accessing the Sites in any manner, you acknowledge
              that you accept the practices and policies outlined in this
              Privacy Policy, and you hereby consent to our collection, use, and
              sharing of your information in the following ways. If you do not
              consent, you may not use the Sites.
            </p>
            <br />

            <h1>What we collect</h1>
            <h2>
              We may use information about you for various purposes, including:
            </h2>
            <p>
              When you place an order, we collect information such as your name,
              email, delivery addresses, a contact telephone number and your
              user name and password. We may also receive and store certain
              information automatically from your computer, such as your IP
              address, browser type and other computer and connection
              information, the time that you logged on and off our websites and
              what items you viewed and searched .
            </p>
            <br />

            <h1>What we do with the information we gather</h1>
            <h2>
              We may use information about you for various purposes, including
              to:
            </h2>
            <p>
              - Provide, maintain and improve the Sites and Products;
              <br />
              - Manage your account and send you related information, including
              confirmations, updates, security alerts and support and
              administrative messages;
              <br />
              - Respond to your comments, questions and requests and provide
              customer service;
              <br />
              - Communicate with you about the Sites, Products, services,
              offers, surveys, events and other news and information we think
              may be of interest to you;
              <br />
              - Monitor and analyze trends, usage and activities in connection
              with the Sites;
              <br />
              - Detect, investigate and prevent fraudulent transactions and
              other illegal activities and protect the rights and property of
              Lambigo and others;
              <br />
              - Personalize and improve the Sites and provide advertisements,
              content and features that match your profile or interests;
              <br />
              - Facilitate contests, sweepstakes and promotions and process and
              deliver entries and rewards;
              <br />
              - Link or combine with information we get from others to help
              understand your needs and provide you with a better experience;
              and
              <br />
              - Carry out any other purpose for which the information was
              collected.
              <br />
            </p>

            <h1>Cookies Policy</h1>
            <p>
              We use certain cookies to improve your shopping experience. And we
              use cookies to identify you on our websites and allow you to place
              the orders. If you prefer, you can set your browser to remove or
              reject browser cookies. Please note that if you choose to remove
              or reject cookies, this could affect the availability and
              functionality of the sites and application
            </p>
            <br />

            <h1>Links to other websites</h1>
            <p>
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </p>
            <br />

            <h1>Security</h1>
            <p>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <br />

            <h1>Controlling your personal information</h1>
            <h2>
              You may choose to restrict the collection or use of your personal
              information in the following ways:
            </h2>
            <p>
              • whenever you are asked to fill in a form on the website, look
              for the option that you can click to indicate that you do not want
              the information to be used by anybody for direct marketing
              purposes
            </p>
            <br />
            <p>
              • if you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by writing to or emailing us at
              support@lambigo.com We will not sell, distribute or lease your
              personal information to third parties unless we have your
              permission or are required by law to do so. We may use your
              personal information to send you promotional information about
              third parties which we think you may find interesting if you tell
              us that you wish this to happen.
            </p>
            <br />

            <h1>Marketing & promotional activity</h1>
            <p>
              Lambigo may use your information to provide you with better
              platform user-experience, send information on products and
              services which may be of interest to you, send via Short Messaging
              Services (SMS) marketing promotions, and share such personal
              information to our business partners on a need-to-know basis to
              render effective services for better customer experience.
            </p>
            <br />

            <h1>NDNC Policy</h1>
            <p>
              By using the Platform, you hereby authorize Us to contact You via
              email, phone, or SMS (Short Message Service), other applications
              (android or apple) linked to phone number on the contact details
              so provided, to furnish You with information about Our Products,
              Services, product delivery, marketing promotions, & other allied
              services offered by Lambigo on its platform. This authorization is
              licit for the mentioned purposes irrespective of whether you are
              registered with the NDNC (National Do Not Call) registry.
            </p>
            <br />

            <h2>Refund Policy </h2>
            <h1>Online Payment</h1>
            <p>
              Refunds will be processed within 48 hours and will be credited to
              the customer account within 5-7 working days depending on the
              issuing bank.
            </p>
            <br />

            <h1>Cash On Delivery</h1>
            <p>
              Refunds will be credited to the customer's account as store credit
              and can be used for the subsequent order in the future.
            </p>

            <h1>Customized Ad Campaigns</h1>
            <p>
              We may also work with third party social media sites, such as
              Facebook, Instagram to serve ads to you as part of a customized
              campaign, unless you notify us that you prefer not to have
              information about you used in this way.
            </p>
            <br />

            <h1>Contact us:</h1>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at and we will try to resolve your concerns.
            </p>
            <br />

            <ul>
              <li>Lambigo</li>
              <li>11-4-25A, kasai street</li>
              <li>Kavali - 524201</li>
              <li>Phone: +91 88863 77763</li>
              <li>Email: support@lambigo.com</li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
