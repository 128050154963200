import React from "react";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <div className="d-flex">
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img alt="" src={process.env.PUBLIC_URL + footerLogo} height={80} />
        </Link>
      </div>
      <p>
        © 2024{" "}
        <a href="//lambigo.in" rel="noopener noreferrer" target="_blank">
          LAMBIGO
        </a>
        <br /> All rights reserved.
      </p>
    </div>
  );
};

export default FooterCopyright;
