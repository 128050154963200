import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../config/ApiConfig";

/**
 * Application API Calls handler
 */
export const axiosApi = axios.create({
  baseURL: API_CONFIG.API_HOST,
  headers: API_HEADERS,
});
