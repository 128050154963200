import PropTypes from "prop-types";
import React from "react";

const TextGridOneSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className="col-lg-4 col-md-4">
      <div
        className={`single-mission ${spaceBottomClass ? spaceBottomClass : ""}`}
      >
        <div className="row d-flex">
          <img
            className="col-2"
            alt=""
            src={process.env.PUBLIC_URL + data.imageurl}
            style={{
              height: 40,

              // width: 320,
              // borderRadius: 50,
              // backgroundColor: "#02025a",
            }}
          />
          <h3 className="col-8" style={{ paddingTop: "5px" }}>
            {data.title}
          </h3>{" "}
        </div>
        <p>{data.text}</p>
      </div>
    </div>
  );
};

TextGridOneSingle.propTypes = {
  data: PropTypes.object,
  spaceBottomClass: PropTypes.string,
};

export default TextGridOneSingle;
