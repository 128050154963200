import logo from "./logo.svg";
import AppHeader from "./components/header";
import HomeLayout from "./layout/HomeLayout";
import {
  Route,
  Routes,
  Navigate,
  HashRouter,
  BrowserRouter as Router,
} from "react-router-dom";
import AboutUs from "./pages/about";
import ContactUs from "./pages/contact";
import ApiCallTest from "./test/ApiCallTest";
import ProductTabRight from "./pages/product/detail/ProductTabRight";
import FooterOne from "./components/footer/FooterOne";
import PrivacyPolicy from "./pages/util/privacy";
import TermsNConditions from "./pages/util/tnc";
import AccountDeletion from "./pages/util/adr";

function App() {
  return (
    <>
      <HashRouter>
        <AppHeader
          layout="container-fluid"
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-1"
        />
        <Routes>
          <Route path="/" element={<HomeLayout />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>
          <Route path="/contact" element={<ContactUs />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/tc" element={<TermsNConditions />}></Route>
          <Route path="/adr" element={<AccountDeletion />}></Route>
        </Routes>
        <FooterOne
          backgroundColorClass="bg-gray"
          spaceTopClass="pt-100"
          spaceBottomClass="pb-70"
        />
      </HashRouter>
    </>
  );
}

export default App;
