import { Fragment, lazy, Suspense } from "react";
import LayoutOne from "./LayoutOne";
// import HeroSliderOne from "../components/slider/HeroSliderOne";
import FeatureIcon from "../components/feature-icons/FeatureIcon";
import AboutUs from "../pages/about";
const HeroSliderOne = lazy(() => import("../components/slider/HeroSliderOne"));
const HomeLayout = () => {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-1"
        >
          {/* hero slider */}
          <HeroSliderOne />

          {/* featured icon */}
          {/* <FeatureIcon spaceTopClass="pt-100" spaceBottomClass="pb-60" /> */}

          <AboutUs />
        </LayoutOne>
      </Suspense>
    </Fragment>
  );
};

export default HomeLayout;
