import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
// import IconGroup from "./IconGroup";
import MobileMenu from "./MobileMenu";
import IconGroup from "./IconGroup";

const AppHeader = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${
        headerPositionClass ? headerPositionClass : ""
      }`}
    >
      <div
        className={` ${
          headerPaddingClass ? headerPaddingClass : ""
        } sticky-bar header-res-padding clearfix ${
          scroll > headerTop ? "stick" : ""
        }`}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-4">
              {/* header logo */}
              {/* <div style={{ float: "left" }}> */}
              <Logo
                imageUrl="/assets/img/logo/LAMBIGO_LOGO.png"
                logoClass="logo"
              />
              {/* </div> */}
              {/* <div
                className="d-none d-sm-block"
                style={{
                  //  marginTop: scroll > headerTop ? "2.5rem" : "3rem",
                  // marginLeft: "85px",
                }}
              >
                <h3
                  style={{
                    fontFamily: "Poppins,sans-serif",
                    color: "goldenrod",
                    textAlign: "center",
                  }}
                >
                  CHOLA ART TREASURES
                </h3>
              </div> */}

              {/* <div className="logo">
                <h1>BIYSS</h1>
              </div> */}
            </div>
            <div className="col-xl-7 col-lg-7 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-8">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  );
};

AppHeader.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default AppHeader;
