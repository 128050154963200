import PropTypes from "prop-types";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { selectedColor, textColor } from "../../assets/util";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <NavLink
              to={"/"}
              // style={({ isActive }) => ({
              //   color: isActive ? selectedColor : textColor,
              // })}

              style={({ isActive, isPending, isTransitioning }) => ({
                // color: isPending ? "red" : isTransitioning ? "red": isActive ? "red":"" ,
                color: isPending
                  ? textColor
                  : isTransitioning
                  ? selectedColor
                  : isActive
                  ? selectedColor
                  : textColor,
              })}
            >
              Home
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"/products"}
              style={({ isActive, isPending, isTransitioning }) => ({
                // color: isPending ? "red" : isTransitioning ? "red": isActive ? "red":"" ,
                color: isPending
                  ? textColor
                  : isActive
                  ? selectedColor
                  : isTransitioning
                  ? "green"
                  : textColor,
              })}
            >
              Products
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to={"/about"}
              style={({ isActive, isPending, isTransitioning }) => ({
                // color: isPending ? "red" : isTransitioning ? "red": isActive ? "red":"" ,
                color: isPending
                  ? textColor
                  : isActive
                  ? selectedColor
                  : isTransitioning
                  ? "green"
                  : textColor,
              })}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contact"}
              style={({ isActive, isPending, isTransitioning }) => ({
                // color: isPending ? "red" : isTransitioning ? "red": isActive ? "red":"" ,
                color: isPending
                  ? textColor
                  : isActive
                  ? selectedColor
                  : isTransitioning
                  ? "green"
                  : textColor,
              })}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default NavMenu;
