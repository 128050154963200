import { API_CONFIG, API_HEADERS } from "../../config/ApiConfig";
import { axiosApi } from "./AaxiosUtil";

/**
 *
 * @param {*} path  API PATH
 * @param {*} data  Optional in GET
 * @param {*} isSendAuthToken  send true for authentocated calls false for non-login users
 * @returns GET API CALL RESPONSE
 */
export const getRequest = (path, data = null, isSendAuthToken = false) => {
  let apiUrl = API_CONFIG.API_HOST + path;

  return axiosApi
    .get(apiUrl, {
      headers: isSendAuthToken
        ? {
            ...API_HEADERS,
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          }
        : API_HEADERS,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return {
          responseStatus: true,
          responseData: response.data,
          statusCode: response.status,
        };
      } else {
        return {
          responseStatus: false,
          responseData: null,
          statusCode: response.status,
        };
      }
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {
          responseStatus: false,
          responseData: error.response.data,
          statusCode: error.response.status,
        };
      } else if (error.request) {
        //reuquest fail
        return {
          responseStatus: false,
          responseData: null,
          statusCode: 500,
        };
      } else {
        //reuquest fail
        return {
          responseStatus: false,
          responseData: error.response.data,
          statusCode: 500,
        };
      }
    });
};
