import { Fragment, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useLocation } from "react-router-dom";

const AccountDeletion = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      {/* <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={location}>Contact</BreadcrumbsItem>
      <Breadcrumb /> */}
      <div className="contact-area pt-70 pb-100">
        <div className="container">
          <div className="custom-row-2">
            <div className="col-lg-4 col-md-5">
              <div className="contact-info-wrap">
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="contact-info-dec">
                    <p>+91- 9515059949</p>
                    <p>+91- 8886377763</p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-globe" />
                  </div>
                  <div className="contact-info-dec">
                    <p>
                      <a href="mailto:contact@lambigo.in">contact@lambigo.in</a>
                    </p>
                    <p>
                      <a href="https://lambigo.in/">https://lambigo.in/</a>
                    </p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="contact-info-dec">
                    <p>
                      D.no: 11-4-25A, Opp Aqsa mosque,Kasai Street,
                      Kavali,S.P.S.R Nellore Dst, AP-524201.
                    </p>
                  </div>
                </div>
                {/* <div className="contact-social text-center">
                  <h3>Follow Us</h3>
                  <ul>
                    <li>
                      <a href="//facebook.com">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="//pinterest.com">
                        <i className="fa fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="//thumblr.com">
                        <i className="fa fa-tumblr" />
                      </a>
                    </li>
                    <li>
                      <a href="//vimeo.com">
                        <i className="fa fa-vimeo" />
                      </a>
                    </li>
                    <li>
                      <a href="//twitter.com">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-7">
              <div className="contact-form">
                <div className="contact-title mb-30">
                  <h2>Account Deletion Request</h2>
                </div>
                <div className="contact-form-style">
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        name="name"
                        placeholder="Registered Phone*"
                        type="number"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        name="email"
                        placeholder="Registered Email*"
                        type="email"
                      />
                    </div>
                    <div className="col-lg-12">
                      <input
                        name="subject"
                        placeholder="Subject*"
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        name="message"
                        placeholder="Your Message*"
                        defaultValue={""}
                      />
                      <button className="submit" type="submit">
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
                <p className="form-messege" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AccountDeletion;
