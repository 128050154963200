import { Fragment, useEffect } from "react";
import SectionTitleWithText from "./SectionTitleWithText";
import FunFactOne from "./funfact/FunFactOne";
import TextGridOne from "../../components/text-grid/TextGridOne";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      {/* section title with text */}
      <SectionTitleWithText spaceTopClass="pt-70" spaceBottomClass="pb-95" />
      {/* banner */}
      {/* <BannerOne spaceBottomClass="pb-70" /> */}
      {/* text grid */}
      <TextGridOne spaceBottomClass="pb-70" />
      {/* fun fact */}
      {/* <FunFactOne
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
        bgClass="bg-gray-3"
      /> */}
      {/* team member */}
      {/* <TeamMemberOne spaceTopClass="pt-95" spaceBottomClass="pb-70" /> */}
      {/* brand logo slider */}
      {/* <BrandLogoSliderOne spaceBottomClass="pb-70" /> */}
    </Fragment>
  );
};

export default AboutUs;
