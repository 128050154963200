import PropTypes from "prop-types";
import React from "react";

import TextGridOneSingle from "./TextGridOneSingle";

const TextGridOne = ({ spaceBottomClass }) => {
  const textGridData = [
    {
      id: "1",
      title: "Our Vision",
      text: "To grow as a top organization in delivering the quality and right product to the customer.",
      imageurl: "/assets/img/wl/wl_one.png",
    },
    {
      id: "2",
      title: "Our Mission",
      text: "To serve the people Quality and Fresh Meat with affordable prices and timely delivery.",
      imageurl: "/assets/img/wl/wl_two.png",
    },
    {
      id: "3",
      title: "High Quality Meat",
      text: "No frozen - Only fresh cuts. Antibiotic free, hormone free, Chicken and Mutton. Chemical free fish and Prawns. Preservative free Pickles and ready to cook.",
      imageurl: "/assets/img/wl/wl_three.png",
    },
    {
      id: "4",
      title: "Friendly & Flexibility",
      text: "Choose and order your favorite cuts in the user-friendly app and stay at your home.",
      imageurl: "/assets/img/wl/wl_four.png",
    },
    {
      id: "5",
      title: "Hygiene Packing",
      text: "We don't compromise in quality, freshness & hygiene. We don't freeze meat.We just maintain 0-4°C to maintain freshness during delivery.",
      imageurl: "/assets/img/wl/wl_five.png",
    },
    {
      id: "6",
      title: "Timely Delivery",
      text: "Cook with Confidence, You enjoy high quality meat we delivered to your door.",
      imageurl: "/assets/img/wl/wl_six.png",
    },
  ];
  return (
    <div
      className={`about-mission-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="row">
          {textGridData &&
            textGridData.map((single, key) => {
              return (
                <TextGridOneSingle
                  data={single}
                  spaceBottomClass="mb-30"
                  key={key}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

TextGridOne.propTypes = {
  spaceBottomClass: PropTypes.string,
};

export default TextGridOne;
