import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";

const TermsNConditions = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="contact-area pt-70 pb-100">
        <div class="container">
          <h3>Terms & Conditions</h3>
          <h1>Welcome to Lambigo</h1>

          <p>
            Dear User, Please look into the following terms and conditions very
            carefully as your use of service is subject to your acceptance and
            compliance with the following terms and conditions.
          </p>
          <br />

          <h3>User Account, and Security</h3>
          <p>
            When you Register/Login through our mobile Apps, we ask for Phone
            Number,Name (Only for first time) to activate your account. Our
            mobile applications will verify phone numbers with OTP process.
            Mobile apps will not ask/capture any other information to complete
            registration/login process. Mobile app will not store sensitive data
            locally in application.
          </p>
          <br />

          <h3>Orders & Delivery</h3>
          <h1>What is the minimum order?</h1>
          <p>The minimum order is Rs.99</p>
          <br />

          <h1>Delivery Charges</h1>
          <p>
            {" "}
            We charge Rs. 15, if the distance is less than 3 KM from Lambigo
            outlet to Delivery Address. We charge Rs. 15 to 40 if delvery
            locaton is morethan 3 KM and Rs. 30 to 50 for Express Deliveries.
          </p>
          <br />

          <h1>Delivery Timing</h1>
          <p>Delivery timing is 7.00am to 8.00pm everyday</p>
          <br />
          <h3>Cancellation & Return Policy</h3>
          <h1>How can I cancel my order?</h1>
          <p>
            You can cancel the order through online or by calling our customer
            support for 88863-77763. We will not be able to cancel the order
            once it is out for delivery unless there is any defect or damage in
            the order or too late in delivering or receiving a wrong item (which
            you haven’t ordered).
          </p>
          <br />

          <h1>Is changing the order possible?</h1>
          <p>
            Yes you can change the order by calling our customer support for
            88863-77763.Keep in mind that few orders cannot be changed and hence
            you have to pay charges for that. But we will try our best to
            fulfill your requirement.
          </p>
          <br />

          <h3>Replacements, Returns, & Refunds</h3>
          <p>
            Lambigo always makes sure that only fresh & quality products with
            quality packing is delivered to the customers. Therefore there will
            be very rare and less chances of replacing the item you purchased.In
            case if any damage or issues came to your notice please contact at
            88863-77763 before 15 minutes, once you received the order. We
            definitely solve the issue, by replacing the order or accepting for
            Return or by Refunding.
          </p>
          <br />

          <h3>Payments</h3>
          <h1>Does COD Available?</h1>
          <p>
            Yes, Cash on Delivery is absolutely available. Once the delivery
            executive reach your location, you can ask him to show the QR code
            from his Lambigo rider app or any Lambigo’s authorized device, you
            can just scan the QR code using Google pay/Phone pay/PayTM for
            paying the amount and enjoy the Hassle free COD.
          </p>
          <br />

          <h1>Does Online Payment Available?</h1>
          <p>Yes online Payment is available</p>
          <br />

          <h1>Is my credit card information safe on your site?</h1>
          <p>
            We redirect to Secured Payment gateway provider, Definitely safe.
            All online payment related transactions are carried out through the
            secure payment gateway and are Payment Card Industry Certified (PCI
            DSS Certified).
          </p>
          <br />

          <h3>Discount Coupons</h3>
          <h2>Refer Lambigo To A Friend</h2>
          <h1>Procedure for referring a friend?</h1>
          <p>
            - Download the Lambigo app, Choose the Refer a Friend option
            <br />
            - Invite your friends via WhatsApp, Facebook, Messenger, Twitter,
            Gmail or Text Message.
            <br />
            - Once your friend download the app and purchase any product for the
            first time using your referral code, he will get a discount coupon.
            <br />
            - And you also get a discount coupon once your friend purchased
            successfully With your referral coupon
            <br />
            <b>Note:</b> The Percentage of Refer a Friend Discount Coupon may
            vary or can change by lambigo at any time. Also refer a friend
            option can be stopped by lambigo at any time.
          </p>
          <br />

          <h3>Governing Law</h3>

          <p>
            These terms shall be governed by and constructed in accordance with
            the laws of India without reference to conflict of laws principles
            and disputes arising in relation hereto shall be subject to the
            exclusive jurisdiction of the courts at Andhra Pradesh.
          </p>
          <br />

          <h3>Sever-ability</h3>

          <p>
            If any provision of the Terms is determined to be invalid or
            unenforceable in whole or in part, such invalidity or unenforceable
            shall attach only to such provision or part of such provision and
            the remaining part of such provision and all other provisions of
            these Terms shall continue to be in full force and effect.
          </p>
          <br />

          <h3>General Conditions</h3>

          <ul>
            <li>
              * We reserve the right to modify or terminate the service for any
              reason, without notice at any time.
            </li>
            <li>
              * We reserve the right to alter these Terms of Use at any time. If
              the alterations constitute a material change to the Terms of Use,
              we will notify you via internet mail or call. What constitutes a
              material change will be determined at our sole discretion, in good
              faith and using common sense and reasonable judgment.
            </li>
            <li>
              * We reserve the right to force forfeiture of any username that
              becomes inactive, violates trademark, or may mislead other users.
            </li>
          </ul>
          <br />

          <p>
            By accessing or using the services provided by Lambigo or our
            subsidiaries or other affiliates through our websites, blogs and
            mobile applications you agree to be bound by the above mentioned
            Terms And Conditions.
          </p>
          <br />
        </div>
      </div>
    </Fragment>
  );
};

export default TermsNConditions;
